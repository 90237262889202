




































































































import { Component, Vue } from "vue-property-decorator";
import AppArchiveSeriesWidget from "@/components/widgets/AppArchiveSeriesWidget.vue";
import AppBadge from "@/components/core/AppBadge.vue";
import AppConfirmReplaceCartModal from "@/components/modals/AppConfirmReplaceCartModal.vue";
import AppCreateItemWidget from "@/components/widgets/AppCreateItemWidget.vue";
import AppDisableSeriesWidget from "@/components/widgets/AppDisableSeriesWidget.vue";
import AppLineItem from "@/components/AppLineItem.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppUpdateSeriesModal from "@/components/modals/AppUpdateSeriesModal.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { findSeries } from "@/utils/api";
import {
  hasMarketerPermissions,
  hasRequestorPermissions,
} from "@/utils/authorization";
import {
  ConflictException,
  NotFoundException,
  notFoundHandler,
} from "@/utils/exception";
import cartHandler from "@/store/cart";

@Component({
  components: {
    AppArchiveSeriesWidget,
    AppBadge,
    AppConfirmReplaceCartModal,
    AppCreateItemWidget,
    AppDisableSeriesWidget,
    AppLineItem,
    AppListGroup,
    AppListGroupItem,
    AppUpdateSeriesModal,
    TheHeader,
    TheMain,
  },
})
export default class Series extends Vue {
  get catalogId(): pro.Id {
    return Number(this.$route.params.catalogId);
  }

  get seriesId(): pro.Id {
    return Number(this.$route.params.seriesId);
  }

  isRequestor = false;
  isMarketer = false;
  lineToBeAdded: pro.Line | null = null;
  seriesDetail: pro.SeriesDetail | null = null;
  showReplaceCartModal = false;
  showUpdateSeriesModal = false;

  async mounted() {
    const catalog = { id: this.catalogId };
    this.isRequestor = await hasRequestorPermissions(catalog);
    this.isMarketer = await hasMarketerPermissions(catalog);
    await this.find();
  }

  /**
   * Finds a Series
   */
  async find() {
    try {
      this.seriesDetail = await findSeries({
        id: this.seriesId,
        catalog: { id: this.catalogId },
      });
    } catch (err) {
      if (err instanceof NotFoundException) notFoundHandler();
    }
  }

  /**
   * Filters the list of available Items
   */
  requestableItems(): pro.ItemDetail[] {
    if (!this.seriesDetail || !this.seriesDetail.items) return [];
    else if (this.isMarketer) return this.seriesDetail.items;
    return this.seriesDetail.items.filter((item: pro.ItemDetail) => {
      return !item.disabled && item.units && item.units.length > 0;
    });
  }

  /**
   * Filters the list of available units
   */
  requestableUnits(units: pro.Unit[]): pro.Unit[] {
    return units.filter((unit: pro.Unit) => !unit.disabled);
  }

  /**
   * Converts an Item to a Line
   */
  convertToLine(item: pro.ItemDetail): pro.Line {
    return {
      unit: { item },
      qty: 1,
    };
  }

  addToCart(line: pro.Line | null, replaceCart = false): void {
    if (line) this.lineToBeAdded = Object.assign({}, line);
    else if (this.lineToBeAdded) line = this.lineToBeAdded;
    else throw new Error("Missing valid line");

    this.showReplaceCartModal = false;

    try {
      cartHandler.addToCart(line, { id: this.catalogId }, replaceCart);
      this.lineToBeAdded = null;
    } catch (err) {
      if (err instanceof ConflictException) {
        this.showReplaceCartModal = true;
      } else throw err;
    }
  }
}
