



































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppCountdown from "@/components/core/AppCountdown.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { createItem } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppCountdown,
    AppLabel,
    AppWidget,
  },
})
export default class AppCreateItemWidget extends Vue {
  @Prop({ required: true })
  readonly catalogId!: pro.Id;

  @Prop({ required: true })
  readonly seriesId!: pro.Id;

  notification: Notification | null = null;
  saving = false;
  item: pro.Item = {
    description: "",
    sku: "",
  };

  mounted() {
    const series = { id: this.seriesId };
    this.item = { ...this.item, series };
  }

  /**
   * Creates a new Item
   */
  async createItem(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      const item = await createItem(this.item, this.catalogId);
      this.goToItem(item);
    } catch (err) {
      this.notification = createErrorNotification(
        "The Item could not be created",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  /**
   * Redirects to an Item
   */
  goToItem(item: pro.Item): void {
    this.$router.push({
      name: "ITEM",
      params: {
        catalogId: String(this.catalogId),
        seriesId: String(this.seriesId),
        itemId: String(item.id),
      },
    });
  }
}
