


















import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { updateSeries } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppWidget,
  },
})
export default class AppDisableSeriesWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly series!: pro.Series;

  notification: Notification | null = null;
  saving = false;

  /**
   * Disables a Series
   */
  async disableSeries(disabled: boolean): Promise<void> {
    this.saving = true;

    try {
      await updateSeries({ ...this.series, disabled });
      this.series.disabled = disabled;
    } catch (err) {
      this.notification = createErrorNotification(
        "The requested changes could not be made",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }
}
