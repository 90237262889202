


















import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { updateSeries } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppWidget,
  },
})
export default class AppArchiveSeriesWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly series!: pro.Series;

  notification: Notification | null = null;
  saving = false;

  /**
   * Archives a Series
   */
  async archiveSeries(): Promise<void> {
    this.saving = false;

    try {
      await updateSeries({ ...this.series, archived: true });
      if (this.series && this.series.catalog) {
        const catalogId = String(this.series.catalog.id);
        this.$router.push({ name: "CATALOG", params: { catalogId } });
      }
    } catch (err) {
      this.notification = createErrorNotification(
        "The requested changes could not be made",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }
}
